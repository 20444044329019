@import "./header";
@import "./footer";

.ant-layout {
  min-height: 100vh;
  background: #fff;
  .ant-layout-content {
    flex-grow: 1;

    // .app-body-main {
    //   max-width: 80%;
    //   margin-inline: auto;
    // }
  }
}
