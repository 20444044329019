.matrix-row {
  display: grid;
  grid-template-columns: 1fr 4fr;
  border: 1px solid var(--clr-neutral-300);
  padding: 1rem;
}

.matrix {
  &__option-items {
    text-align: center;
  }
  &__checkbox-group {
    &.ant-radio-group {
      text-align: center;
    }
    &.ant-checkbox-group {
      .ant-checkbox-wrapper {
        border: none;
        margin-bottom: 0;
        padding-right: unset;
      }

      .ant-checkbox-wrapper .ant-checkbox {
        position: absolute;
        right: unset;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
