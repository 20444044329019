.multi-blank {
  &__question {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 1rem;
    // align-items: center;
    font-size: 1.6rem;
    position: relative;
    .placeholder-blank {
      width: 208px;
      height: 52px;
      display: inline-block;
      opacity: 0;
      visibility: hidden;
    }
    table {
      width: 100%;
      &,
      & th,
      & td {
        border: 1px solid #ccc;
      }
      th,
      td {
        padding: 4px 8px;
      }
      tbody {
        tr:first-child {
          font-weight: 500;
        }
      }
      & ~ .multi-blank {
        &__drop-wrap {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin-top: -3.5rem;
        }

        &__drop-input {
          height: 50px;
          width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--clr-neutral-300);
          padding-inline: 1.5rem;
          &--field {
            text-align: center;
            line-height: 1;
            .placeholder {
              color: var(--clr-neutral-300);
              font-size: 1.2rem;
              &--white {
                color: white;
              }
            }
          }
        }
      }
    }
  }

  &__drop-wrap {
    display: inline-block;
    margin-inline: 0.5rem;
    margin-bottom: 0.25rem;
  }

  &__drop-input {
    min-height: 50px;
    min-width: 200px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--clr-neutral-300);
    padding-inline: 1.5rem;
    &--field {
      text-align: center;
      line-height: 1;
      .placeholder {
        font-size: 1.2rem;
        color: var(--clr-neutral-300);
        &--white {
          color: white;
        }
      }
    }
  }
}
