.ant-layout-footer {
  position: fixed;
  bottom: 0;
  left: 0;  
  right: 0;
  padding-block: 7px;
  background: #232323;
  p {
    font-size: 1.2rem;
    text-align: center;
    color: #fff;
    font-weight: 300;
  }
}
