.time-up-modal {
    .ant-modal-content {
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        box-shadow: none;
        position: relative;

        .ant-modal-body {
            padding: 50px;
        }

        .total-score {
            position: absolute;
            top: 0;
            right: 5px;
            height: 110px;
            margin: 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            font-weight: bold;
            letter-spacing: 2px;
            padding: 15px 10px 5px;

            span {
                margin-bottom: 5px;
                font-size: 20px;
            }

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: calc(77.95px / 2) solid transparent;
                border-right: calc(77.95px / 2) solid transparent;
                border-bottom: 30px solid #fff;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
            }

            &.pass-score {
                &::before {
                    border-left: calc(87.8px / 2) solid transparent;
                    border-right: calc(87.8px / 2) solid transparent;
                }
            }
        }

        .result-header {
            margin-bottom: 15px;

            p{
                color: #73738c;
            }
        }
    }

}