.progress-info {
  // background-color: var(--clr-secondary-500);
  color:#434343;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  &__grid {
    background-color: #ecffee;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1.5rem 90px;
    align-items: center;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &__ratio {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.25rem;
  }
  &__title {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    // line-height: normal;
  }
  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    line-height: normal;
    font-size: 16px;
  }

  &__percent {
    background-color: white;
    height: 5px;
    width: 100%;
    &--complete {
      background-color: var(--clr-primary-500);
      height: 100%;
    }
  }
}
