.question-button-holder {
  .ant-btn {
    min-width: 100px;
    padding: 8px 20px;
  }
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 3.6rem;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 8px 20px;

  .ant-form-item {
    margin-bottom: 0;
  }
}
