$primary: #2b8eec;
$secondary: #68aa6f;
$primary-light-background: #e5f1fc;
$text-primary: #101010;
$text-secondary: #767676;

$red: #b02e2e;
$green: #68aa6f;
$red-light: #fdf3f3;
$green-light: #f1f8f1;
$blue-light: #e5f1fc;

$font-primary: "SF Pro Display";

$colors: (
  "primary": "$primary",
  "red": #b02e2e,
  "green": #68aa6f,
  "red-light": #fdf3f3,
  "green-light": #f1f8f1,
  "blue-light": #e5f1fc,
  "yellow": #e3c042,
  "blue": #2b8eec,
  "orange": #fa9820,
);

@each $key, $val in $colors {
  .color-#{$key} {
    color: $val;
  }

  .bg-color-#{$key} {
    background-color: $val;
  }
}

$colors: (
  primary: (
    // blue
    50: #f4f6f7,
    100: #eaf4fd,
    200: #aad2f7,
    300: #80bbf4,
    400: #55a5f0,
    500: #2b8eec,
    600: #2780d4,
  ),
  secondary: (
    100: #f0f7f1,
    200: #c3ddc5,
    300: #a4cca9,
    400: #86bb8c,
    500: #68aa6f,
    600: #5e9964,
  ),
  neutral: (
    0: #ffffff,
    50: #f9fafb,
    100: #e8e8e8,
    200: #dfe3e8,
    300: #bdbdbd,
    400: #606060,
    500: #5c5c5c,
    600: #4b5563,
    700: #374151,
    800: #070707,
    900: #000000,
  ),
  error: (
    200: hsl(345 88% 86%),
    400: hsl(345 88% 46%),
    600: hsl(345 88% 36%),
  ),
  warn: (
    200: hsl(36 100% 80%),
    400: hsl(36 100% 50%),
    600: hsl(36 100% 40%),
  ),
  success: (
    200: hsl(104 30% 85%),
    400: hsl(104 30% 55%),
    600: hsl(104 30% 45%),
  ),
);
