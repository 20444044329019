@mixin absoluteCover() {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin flexInlineCenter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
