.ant-btn {
  @include flexInlineCenter;
  font-family: $font-primary;
  font-weight: 500;
  font-size: 1.6rem;
  height: auto;
  letter-spacing: 1px;
  height: auto;
  border-radius: 7px;
  &.ant-btn-primary {
    background: $primary;
    font-family: $font-primary;
    padding: 0.5rem 3rem;
  }
}
