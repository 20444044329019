.nclx-container {
  padding-inline: 4rem;
  max-width: 144rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media (max-width: 768px) {
    padding-inline: 1.5rem;
  }

  &.common-container {
    // min-height: 610px;
    max-height: calc(100vh - 180px);
    overflow: auto;
    padding: 20px 0;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d3d3d3;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .submit-button-holder {
    position: fixed;
    bottom: 50px;
    right: 15px;
    left: 20px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: 992px) {
    padding-inline: 3rem;
    .submit-button-holder {
      right: 50px;
      left: 50px;
    }
  }
}

.ant-radio-inner {
  border-color: #ccc;
}
