html {
  font-size: 62.5%;
}

body {
  font-family: $font-primary;
  font-size: 1.6rem;
  color: $text-primary;
  letter-spacing: 0.3px;
  font-weight: 400;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

.nclx-op-format__radio {
  margin-top: 30px;
}

.nclx-op-format__radio .ant-radio-group {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  justify-content: flex-start;
}

.nclx-op-format__radio .ant-radio-wrapper .index-no {
  // padding: 10px;
  margin-right: 0;
  background-color: transparent;
}

.exam-panel__question .ant-input {
  padding: 12px 14px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.question-title {
  margin: 0 auto 20px;
  color: #3b3664;
  font-weight: normal;
  font-size: 1.8rem;
  & * {
    font-size: 1.8rem;
  }

  &:is(span, .new-case-question) {
    font-weight: 600 !important;
    color: #3b3664 !important;
    font-family: unset !important;
    font-size: 18px !important;
  }

  &:is(.case-question-title, .new-case-question) {
    margin: 0 auto 20px;
  }

  &.new-case-question {
    div {
      display: inline;
    }
  }
}

.ant-layout-header {
  line-height: 1.5;
}

.audio-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 20px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 40%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    background: #ccc;
    width: 1px;
    height: 100%;
  }
  .audio {
    width: 39%;
    text-align: center;
  }

  .ant-form-item {
    width: 55%;
  }
}
