.fan-diagram {
  &__option-grid {
    margin-top: 2rem;
    padding: 0.5rem;
    // border: 1px solid #ccc;
    &:nth-of-type(even) {
      border-left: none;
    }
  }

  &__drop-wrapper {
    display: grid;
    gap: 5rem;
  }

  &__drop-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }

  &__drop-input {
    width: 100%;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
    & > div {
      width: 100%;
    }
    &--3,
    &--5 {
      grid-column: 3;
    }
    &--2 {
      grid-column: 2;
      color: white;
      background-color: var(--clr-primary-600);
      border-color: var(--clr-primary-600);
    }

    &--field {
      text-align: center;
      .placeholder {
        color: var(--clr-neutral-300);
        &--white {
          color: white;
        }
      }
    }
  }

  &__list {
    & > * + * {
      margin-top: 0.5rem;
    }
    background-color: var(--clr-neutral-100);
    padding: 1rem;
  }

  &__list-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 1rem 0.75rem;
    border: 1px solid #f1f5f9;
    background-color: white;
  }

  &__option-grid-title {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1rem;
  }
}
