
@font-face {
  font-family: SFProDisplay;
  src: url("http://fonts.cdnfonts.com/css/sf-pro-display") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: SFProDisplay;
  src: url("http://fonts.cdnfonts.com/css/sf-pro-display") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: SFProDisplay;
  src: url("http://fonts.cdnfonts.com/css/sf-pro-display") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: SFProDisplay;
  src: url("http://fonts.cdnfonts.com/css/sf-pro-display") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: SFProDisplay;
  src: url("http://fonts.cdnfonts.com/css/sf-pro-display") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: SFProDisplay;
  src: url("http://fonts.cdnfonts.com/css/sf-pro-display") format("opentype");
  font-weight: 800;
}
@font-face {
  font-family: SFProDisplay;
  src: url("http://fonts.cdnfonts.com/css/sf-pro-display") format("opentype");
  font-weight: 900;
}
