.authentication-form-wrapper {
  display: grid;
  place-items: center;
  height: 100vh;
  background: #f7f7f7;
  h2 {
    font-size: 4.8rem;
    font-weight: 700;
  }
  p {
    margin-bottom: 20px;
    color: #767676;
  }
  .authentication-form {
    max-width: 560px;
    border-radius: 8px;
    width: 100%;
    padding: 30px;
    box-shadow: 6px 6px 42px rgba(0, 0, 0, 0.08);
    background: #fff;
    .ant-form {
      // .ant-form-item-control {
      //   width: 100%;
      // }
      .ant-form-item {
        margin-bottom: 17px;

        &.form-submit-btn {
          text-align: center;
          margin: 30px 0 10px;
          .ant-btn-primary {
            min-width: 180px;
            padding-block: 10px !important;
            font-family: $font-primary;
            line-height: 1;
          }
        }
      }
      .ant-form-item-label {
        margin-bottom: 0;
        label {
          text-transform: capitalize;
          letter-spacing: 0.3px;
          height: auto;
        }
        .ant-form-item-required {
          &:before {
            position: absolute;
            right: -13px;
            top: 6px;
          }
        }
      }
      .privacy {
        margin-top: 10px;
        color: #878787;
      }
    }
    .register-link {
      text-align: center;
      font-size: 1.4rem;

      a {
        margin-left: 7px;
        font-weight: 700;
      }
    }

    &.authentication-form--examinee {
      text-align: center;
      padding-bottom: 40px;

      img {
        width: 100px;
        display: inline-block;
      }

      h3 {
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 24px;
        margin-bottom: 30px;
      }

      h4 {
        margin-bottom: 5px;
        font-weight: 300;
        text-align: left;
        letter-spacing: 0.5px;
      }
    }
  }
}
