.case-question-list {
  .case-question-holder {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #d3d3d3;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .divider-border {
      border: 1px solid #f0f0f0;
      height: 100%;
      width: 1px;
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }

    .question-item {
      max-height: calc(100vh - 150px);
      overflow: auto;
      padding: 20px;
    }

    .payloads-holder {
      max-height: calc(100vh - 200px);
      padding: 20px;
      overflow: auto;

      .payloads {
        border: 1px solid #f0f0f0;

        // .ant-tabs-nav {
        //     &::before {
        //         display: none;
        //     }
        // }

        .ant-tabs-tab {
          color: #73738c;

          .ant-tabs-tab-btn {
            font-size: 18px;
          }

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #434343;
            }
          }
        }

        .ant-tabs-content-holder {
          padding: 16px;

          :is(p, span) {
            font-size: 16px;
            line-height: 2.5;
            color: #73738c !important;
            font-family: unset !important;
          }

          img {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.nclx-op-format__input {
  input {
    max-width: 500px;
    padding: 1rem 0.75rem;
    font-size: 1.6rem;
  }
}
