.question-list-wrapper {
  max-width: 1080px;
  margin-inline: auto;
  padding-inline: 40px;
  padding-block: 40px;
}

.question-list-header {
  .question-status-meta {
    display: flex;
    justify-content: space-between;

    .total-time-elapsed {
      span {
        color: #8b8b8b;
        font-size: 1.4rem;
      }
    }
  }

  .status-bar {
    position: relative;
    background: #d2d2d2;
    width: 100%;
    height: 7px;
    border-radius: 35px;
    margin-top: 5px;
    overflow: hidden;

    .completion-stat {
      position: absolute;
      top: 0;
      left: 0;
      background: $secondary;
      height: 7px;
      width: 44%;
    }
  }
}

.question-list-body {
  text-align: left;
  margin-top: 40px;

  .question-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    br {
      display: none;
    }

    .ant-form.ant-form-vertical {
      width: 100%;
    }
  }

  h2 {
    line-height: 1.3;
    margin-bottom: 15px;
  }

  .ant-form-item {
    width: 100%;
    margin-bottom: 0;
  }

  .input--fill-blank {
    width: 50%;
    margin-inline: auto;
    margin-bottom: 30px;

    h3 {
      margin-bottom: 7px;
    }
  }

  .ant-radio-group {
    margin-top: 30px;
    text-align: left;
    width: 70%;

    .ant-space {
      width: 100%;
    }

    .ant-radio-wrapper {
      width: 100%;
      border: 1px solid #ccc;
      position: relative;
      border-radius: 8px;
      margin-bottom: 10px;
      padding-right: 23px;

      .ant-radio {
        position: absolute;
        right: 15px;
        top: 15px;
      }

      span:nth-child(2) {
        display: flex;
        align-items: center;
        padding-block: 7px;
        font-size: 16px;
        font-weight: 500;

        .index-no {
          font-size: 18px;
          border-radius: 7px;
          font-weight: 700;
          padding: 4px 12px;
          background: $primary-light-background;
          margin-right: 15px;
          border-radius: 4px;
        }
      }
    }

    .ant-checkbox-wrapper {
      width: 100%;
      border: 1px solid #ccc;
      position: relative;
      border-radius: 8px;
      margin-bottom: 10px;
      padding-right: 23px !important;

      .ant-checkbox {
        position: absolute;
        right: 15px;
        top: 15px;

        .ant-checkbox-inner {
          border-radius: 50%;
        }
      }

      span:nth-child(2) {
        display: flex;
        align-items: center;
        padding-block: 7px;
        font-size: 16px;
        font-weight: 500;

        .index-no {
          font-size: 18px;
          border-radius: 7px;
          font-weight: 700;
          padding: 4px 12px;
          background: $primary-light-background;
          margin-right: 15px;
          border-radius: 4px;
        }
      }
    }
  }
}

.ant-space {
  width: 75%;
  margin-block: 40px;
}

.index-no {
  font-size: 18px;
  border-radius: 7px;
  font-weight: 700;
  padding: 4px 12px;
  background: $primary-light-background;
  margin-right: 15px;
  border-radius: 4px;
}

.question-input input {
  position: absolute;
  opacity: 0;
}

.question-input {
  cursor: pointer;
  width: 48.5%;
}

.question-input .input-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
  align-items: center;
}

.question-input .plan-details h3 {
  color: #232323;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.question-input .input-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.question-input input+.input-content:after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  background: $secondary;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  border: 8px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px $secondary;
  box-shadow: 0px 0px 0px 2px $secondary;
}

.question-input input:checked+.input-content {
  border: 2px solid $secondary;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;

  .index-no {
    background: lighten($secondary, 20%);
  }
}

.question-input input:checked+.input-content:after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  background: $secondary;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px $secondary;
  box-shadow: 0px 0px 0px 2px $secondary;
}

.question-input input:checked+.input-content {
  border: 2px solid $secondary;
  background: lighten($secondary, 40%);
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

.modal--exibit {
  .ant-modal-body {
    display: flex;
    justify-content: center;
  }

  .ant-modal-footer {
    .ant-btn-default {
      display: none !important;
    }
  }
}

.modal--final-score {
  .ant-modal-body {
    text-align: center;

    .result-header {
      h2 {
        font-size: 32px;
      }
    }

    .total-score {
      display: inline-block;
      background: #ddf8e0;
      padding: 5px 15px;
      border-radius: 5px;
      margin-block: 15px;
      color: $secondary;
      font-size: 20px;

      strong {
        margin-left: 2px;
      }
    }

    .fail-score {
      background: #fac8bd;
      color: $red;
    }

    .overal-performance {
      background: #f3f3f3;
      padding: 20px 30px;
      width: 70%;
      margin-inline: auto;
      border-radius: 14px;
      margin-bottom: 25px;

      h3 {
        margin-bottom: 10px;
      }

      .answer-stat {
        margin-bottom: 5px;

        &.correct {
          color: $secondary;
        }

        &.wrong {
          color: #ff3300;
        }

        .anticon {
          font-size: 18px;
          margin-right: 7px;
        }
      }
    }
  }

  .ant-modal-footer,
  .ant-modal-close-x {
    display: none;
  }

  .ant-btn.ant-btn-primary {
    background: #2b8eec;
    // font-family: $font-primary !important;
    font-family: $font-primary;
    // padding: 15px 25px 0px;
  }
}

.ant-btn {
  font-family: $font-primary;
}

.drag-area-wrapper {
  .drag-container {
    padding: 10px;
    border: 1px solid #ccc;

    .task-list {
      background: #f5f5f5;

      .drag-item {
        padding: 8px;
        border: 1px solid #ccc;
      }
    }
  }
}

.ant-checkbox-group {
  .ant-checkbox-wrapper {
    width: 100%;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 8px;
    margin-bottom: 10px;
    padding-right: 23px;
    margin-left: 0px;

    &:first-child {

      .index-no {
        background: $primary-light-background;
      }
    }

    .ant-checkbox {
      position: absolute;
      right: 15px;
      top: 15px;
    }

    span:nth-child(2) {
      display: flex;
      align-items: center;
      padding-block: 7px;
      font-size: 16px;
      font-weight: 500;

      .index-no {
        font-size: 18px;
        border-radius: 7px;
        font-weight: 700;
        padding: 4px 12px;
        background: $primary-light-background;
        margin-right: 15px;
        border-radius: 4px;
      }
    }

    .index-title {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.form--question-list {
  .ant-input {
    width: 60%;
    margin-bottom: 30px;
  }
}

.validation-msg {
  color: #ff3300;
  margin-block: 10px;
}

.title-termination {
  font-size: 16px;
  color: #cc3300;
  margin-bottom: 15px;
  line-height: 1.4;
  font-weight: 400;
}

.exhibit-question {
  display: flex;
  justify-content: center;
  margin-block: 25px;
}

.spinner-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question {
  font-size: 18px;
}

.exam-panel {
  height: calc(100dvh - 133px);
  // overflow: hidden;

  // &.not-case-question {
  //   padding-block: 6rem;
  // }

  // &__col {
  //   padding-block: 2.4rem;

  //   @media (min-width: 768px) {
  //     padding-block: 6rem;
  //   }
  // }

  &__question {
    font-size: 1.8rem;
    font-weight: 500;

    img {
      margin-inline: auto;
    }
  }
}