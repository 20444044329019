.ant-modal-confirm {
  &-btns {
    
    .ant-btn.ant-btn-primary {
      padding: 5px 35px;
      // font-family: $font-primary !important;
      font-family:  $font-primary;
    }
  }
}
