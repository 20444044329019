.welcome-wrapper {
  max-width: 750px;
  margin-inline: auto;
  margin-top: 100px;
  text-align: center;

  h1 {
    font-size: 3.8rem;
    margin-bottom: 15px;
  }

  .note {
    background: #f3f3f3;
    padding: 15px;
    border-radius: 7px;
    margin-block: 25px;
    font-size: 1.4rem;
  }

  a {
    padding-bottom: 5px;
    border-bottom: 1px solid $primary;
    color: $primary;
  }
}

.examination-panel {
  &__row {
  }
  &__col {
    padding: 1.5rem;
    @media (min-width: 768px) {
      padding: 3rem;
    }

    &:nth-child(2) {
      border-left: 2px solid var(--clr-neutral-300);
    }
  }
}
